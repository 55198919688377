<template>
    <div class="p-wrap">
      <!-- 功能区 -->
      <div class="p-header">
        <div class="header-title">
          <div class="title-left">数电企业管理</div>
          <div class="title-right">
            <span @click="handleShowDialog('insert')"> <img src="@/assets/icon/goodsInfo/insert.png" />创建</span>
            <!-- <span @click="importVisible = true"> <img src="@/assets/icon/goodsInfo/income.png" />批量导入 </span> -->
          </div>
        </div>
        <div class="header-select">
          <el-form class="form" ref="customerFormList" :model="filter" label-width="100px"   :inline="true">
            <!-- <el-row> -->
              <!-- <el-col :span="6"> -->
                <!-- <el-form-item label="所属组织">
                  <organization-select :model="filter"></organization-select>
                </el-form-item> -->
                <el-form-item label="纳税人名称">
                  <el-input v-model="filter.kpdwmc" placeholder="请输入纳税人名称" @keyup.enter.native="handleQuery()" />
                </el-form-item>
              <!-- </el-col>
              <el-col :span="6"> -->
                <el-form-item label="纳税人识别号">
                  <el-input v-model="filter.kpdwdm" placeholder="请输入纳税人识别号" @keyup.enter.native="handleQuery()" />
                </el-form-item>
              <!-- </el-col>
              <el-col :span="6"> -->
                <el-form-item label="省份" >
                  <el-select v-model="filter.prov" clearable>
                    <el-option v-for="item in provinceOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
                </el-form-item>
               
              <!-- </el-col>
              <el-col :span="5"> -->
                <el-form-item>
                  <span v-if="!selectExpended" @click="selectExpended = !selectExpended" style="color: #1890ff">展开<i class="el-icon-arrow-down"></i></span>
                  <span v-else @click="selectExpended = !selectExpended" style="color: #1890ff">收起<i class="el-icon-arrow-up"></i></span>
                  <el-button type="default" @click="handleReset">重置</el-button>
                  <el-button type="primary" @click="handleQuery()">查询</el-button>
                </el-form-item>
               
              <!-- </el-col> -->
            <!-- </el-row> -->
            <el-row style="margin-top: 10px" v-if="selectExpended">
              <!-- <el-col :span="6">
                <el-form-item label="往来类别">
                  <el-select v-model="filter.contactType" placeholder="往来类别" clearable style="width: 220px">
                    <el-option v-for="type in contactType" :key="type.value" :label="type.label" :value="type.value"/>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6"> -->
                <!-- <el-form-item label="税号">
                  <el-input v-model="filter.taxNo" placeholder="请输入客户税号" @keyup.enter.native="handleQuery()"/>
                </el-form-item> -->
                <el-form-item label="添加时间">
                  <el-date-picker v-model="filter.created" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" @keyup.enter.native="handleQuery()"> </el-date-picker>
                </el-form-item>
                <el-form-item label="额度类型">
                  <el-select v-model="filter.limitType" clearable>
                    <el-option v-for="item in limitTypeOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="企业到期时间">
                  <el-date-picker v-model="filter.gqsj" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" @keyup.enter.native="handleQuery()"> </el-date-picker>
                </el-form-item>
            </el-row>
            <el-row style="margin-top: 10px" v-if="selectExpended"> 
                
                <!-- <el-form-item label="校验数电密码">
                  <el-input v-model="filter.code" placeholder="请选择是否校验数电密码" @keyup.enter.native="handleQuery()"></el-input>
                </el-form-item> -->
                <el-form-item label="身份">
                  <el-select v-model="filter.dzswjsf" @change="changeNoYes" clearable>
                        <el-option v-for="item in elecTypeOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
              <!-- </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="客户标签">
                  <el-select v-model="filter.labelId" clearable placeholder="客户标签" style="width: 220px">
                    <el-option v-for="type in labelData" :key="type.value" :label="type.label" :value="type.value"/>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- 内容区 -->
      <div class="p-content">
        <div class="main-top">
          <div class="top-left">
            <span>企业</span>
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"> 全选 </el-checkbox>
              <div class="part"></div>
              <el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="item in colSelect" :label="item" :key="item" style="display: block; margin-top: 6px"></el-checkbox>
              </el-checkbox-group>
              <div slot="reference" class="rowSet">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span>列设置</span>
              </div>
            </el-popover>
          </div>
          <div>
            <!-- <el-button type="primary" @click="handlePushCustom">推送</el-button>
            <el-button type="primary" @click="handleSyncCustom">同步往来单位</el-button> -->
            <!-- <el-button type="primary" @click="handleBatchExport">批量导出</el-button>
            <el-button type="danger" @click="handleBatchDelete">批量删除</el-button> -->
          </div>
        </div>
        <div class="p-table">
          <el-table ref="customerInfoTable" v-loading="tableLoading" :data="records" :header-cell-style="handleHeaderCellStyle" border
                    stripe @selection-change="handleSelection">
            <el-table-column fixed="left" type="selection" width="50"/>
            <el-table-column type="index" label="序号" width="60"></el-table-column>
            <!-- <el-table-column v-if="colData[0] && colData[0].isTrue" prop="code" label="编码"></el-table-column> -->
            <el-table-column v-if="colData[0] && colData[0].isTrue" label="纳税人名称" min-width="140" prop="kpdwmc"
                             show-overflow-tooltip></el-table-column>
            <!-- <el-table-column v-if="colData[2] && colData[2].isTrue" label="往来类别" min-width="70"
                             prop="contactType" show-overflow-tooltip></el-table-column>
            <el-table-column v-if="colData[3] && colData[3].isTrue"  :formatter="customerFormat" label="客户类型"
                             min-width="100"
                             prop="type" show-overflow-tooltip></el-table-column>
            <el-table-column v-if="colData[14] && colData[14].isTrue" label="客户标签" min-width="100"
                             prop="labelName" show-overflow-tooltip></el-table-column> -->
            <el-table-column v-if="colData[1] && colData[1].isTrue" label="纳税人识别号" min-width="160" prop="kpdwdm"
                             show-overflow-tooltip></el-table-column>
            <el-table-column v-if="colData[2] && colData[2].isTrue" label="额度状态" min-width="140"
                             prop="limitType" show-overflow-tooltip :formatter="limitTypeFormat"></el-table-column>
            <el-table-column v-if="colData[3] && colData[3].isTrue" label="省份" min-width="140"
                             prop="prov" show-overflow-tooltip></el-table-column>
            <el-table-column v-if="colData[4] && colData[4].isTrue" label="创建时间" min-width="140" prop="created"
                             show-overflow-tooltip></el-table-column>
            <el-table-column v-if="colData[5] && colData[5].isTrue" label="过期时间" min-width="80" prop="gqsj"
                             show-overflow-tooltip :formatter="limitTypeFormat"></el-table-column>
            <el-table-column v-if="colData[6] && colData[6].isTrue" label="正式额度到期时间" prop="proEndDays" show-overflow-tooltip
                             width="120" >
                             <template slot-scope="scope">
                              <span v-if="scope.row.proEndDays!=null && scope.row.proEndDays!=''">{{ fmtData(scope.row.proEndDays) }}</span>
                            </template>
                          </el-table-column>
            <el-table-column v-if="colData[7] && colData[7].isTrue" label="正式额度起始时间" prop="proStatrDays" show-overflow-tooltip
                             width="160" >
                             <template slot-scope="scope">
                              <span v-if="scope.row.proStatrDays!=null && scope.row.proStatrDays!=''">{{ fmtData(scope.row.proStatrDays) }}</span>
                            </template>
                            </el-table-column>
            <el-table-column v-if="colData[6] && colData[6].isTrue" label="测试额度到期时间" prop="testEndDays" show-overflow-tooltip
                            width="120" >
                            <template slot-scope="scope">
                              <span v-if="scope.row.testEndDays!=null && scope.row.testEndDays!=''">{{ fmtData(scope.row.testEndDays) }}</span>
                            </template>
                          </el-table-column>
            <el-table-column v-if="colData[7] && colData[7].isTrue" label="测试额度起始时间" prop="testStatrDays" show-overflow-tooltip
                             width="160" >
                             <template slot-scope="scope">
                              <span v-if="scope.row.testStatrDays!=null && scope.row.testStatrDays!=''">{{ fmtData(scope.row.testStatrDays) }}</span>
                            </template>
                            </el-table-column>
            <el-table-column v-if="colData[8] && colData[8].isTrue" label="试用额度到期时间" prop="tryEndDays" show-overflow-tooltip
                            width="120">
                            <template slot-scope="scope">
                              <span v-if="scope.row.tryEndDays!=null && scope.row.tryEndDays!=''">{{ fmtData(scope.row.tryEndDays) }}</span>
                            </template>
                          </el-table-column>
            <el-table-column v-if="colData[9] && colData[9].isTrue" label="试用额度起始时间" prop="tryStatrDays" show-overflow-tooltip
                             width="160" >
                            <template slot-scope="scope">
                              <span v-if="scope.row.tryStatrDays!=null && scope.row.tryStatrDays!=''">{{ fmtData(scope.row.tryStatrDays) }}</span>
                            </template>
                            
                            </el-table-column>
            <el-table-column v-if="colData[10] && colData[10].isTrue" fixed="right"  label="操作" prop="operate"
                             width="120">
              <template slot-scope="scope">
                <div class="operate-button">
                  <el-button size="medium" style="padding: 0" type="text" @click="handleShowDialog('update', scope.row)">
                    修改
                  </el-button>
                  <el-button size="medium" style="padding: 0" type="text" @click="handleDelete(scope.row)">手动续费
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="records.length > 0" class="p-page">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleQuery" :current-page.sync="filter.page" :page-sizes="[15, 30, 50, 100]" :page-size="filter.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
      <!-- 弹窗 -->
      <el-dialog append-to-body :title="editTitle"  :visible.sync="editVisible"  width="1100px" :show-close="true">
        <enterprise-add v-if="editVisible" :lockObject="lockObject" :isModify="this.isModify" ref="entpriseForm" @handleCancelEditDialog="handleCancelEditDialog"></enterprise-add>
      </el-dialog>
      <el-dialog append-to-body title="批量导入" :visible.sync="importVisible" width="500px" :show-close="false">
        <customer-import @handReloadCustomerList="handReloadCustomerList" @handleCancelImportDialog="importVisible = false"></customer-import>
      </el-dialog>
      <!-- 手动续费 -->
      <el-dialog title="手动续费" append-to-body width="40%" :visible.sync="invoiceBatchDeleteVisible">
        <!-- <div class="dialog-delete"> -->
          <label style="font-style: normal;">续费方式：</label>
          <el-select v-model="renewTyope" >
            <el-option v-for="item in renewTyopeOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
          </el-select>
        <!-- </div> -->
        <div class="dialog-footer" slot="footer">
          <el-button type="default" @click="cancelRenew">取消</el-button>
          <el-button type="primary" @click="confirmRenew">确认</el-button>
        </div>
      </el-dialog>
      
    </div>
  </template>
  
  <script>
  import {tableStyle} from '@/util/mixins';
  import {deleteCustomerByIds, handleExportExcel,  pushCustomer, syncCustom} from '@/service/customer.js';
  import{ list,handElecEnt} from '@/service/elecEnt.js'
  import OrganizationSelect from '@/components/widgets/OrganizationSelect';
//   import CustomerTemplateDownload from './CustomerTemplateDownload';
//   import CustomerImport from './CustomerImport';
//   import CustomerEdit from './CustomerEdit';
  import {ENTERPRISE_INFO_LIST,PROVINCE_INFO,ELEC_TYPE} from '@/content/enterprise';
  import ProjectImport from '@/views/voucher-manage/items/ProjectImport.vue';
  import {getAllLabels} from "@/service/system/label";
  import EnterpriseAdd from './EnterpriseAdd.vue'
  
  export default {
    name: 'customerList',
    components: { ProjectImport, OrganizationSelect,EnterpriseAdd },
    mixins: [tableStyle],
    data() {
      return {
        labelData: [],
        colData: ENTERPRISE_INFO_LIST,
        isIndeterminate: true,
        colOptions: [], //列选项
        checkAll: false, //全选
        selections: [], //选择行
        colSelect: ENTERPRISE_INFO_LIST.map((i) => i.title),
        provinceOptions:PROVINCE_INFO,
        elecTypeOptions:ELEC_TYPE,
        isModify:false,
        renewTyope:undefined,
        renewTyopeOptions:[
          {
            label:'正式数电企业',
            value:0
          },
          {
            label:'测试数电企业续费',
            value: 1
          },
          {
            label:'试用数电企业',
            value: 2
          }
        ],
        limitTypeOptions:[
          { label:'正式',
            value:0
          },
          {
            label:'试用',
            value:1
          }
        ],
        // contactType: [
        //   {label: '全部', value: ''},
        //   {label: '客户', value: '客户'},
        //   {label: '两者皆是', value: '两者皆是'},
        //   { label: '供应商', value: '供应商' }
        // ],
        // 筛选条件
        filter: {
          orgId: 0,
          code: '',
          name: '',
          taxNo: '',
          // contactType: '',
          labelId: '',
          page: 1,
          size: 15
        },
        lockObject: {
          orgId: Number(localStorage.getItem('orgId')),
          code: undefined,
          name: '',
          
        },
        customer: {
          orgId: Number(localStorage.getItem('orgId')),
          code: undefined,
          name: ''
        },
        // 版本号信息
        versionInfo: {
          orgId: '',
          taxVersionNo: ''
        },
        handMatic:{
          resultId:undefined,
          renewTyope:undefined,
          kpdwdm:undefined,
          kpdwmc:undefined,
          enterpriseId:undefined
        },
        tableLoading: false, // 表格加载loading
        versionVisible: false, // 版本号弹窗
        importVisible: false, // 导入弹窗
        editVisible: false, // 编辑弹窗
        editTitle: '', // 编辑弹窗标题
        records: [],
        total: 0, // 数据总数count
        serialNo: '', //商品编码
        invoiceBatchDeleteVisible: false,
        singleSign: false,
        singleCustomerId: '',
        selectExpended: false,
        params: {
          ids: ''
        }
      };
    },
    created() {
      this.handleQuery();
      this.settingOptions();
      this.handleGetlineSet();
      this.initLabels()
    },
    watch: {
      colOptions(valArr) {
        const arr = this.colSelect.filter((i) => valArr.indexOf(i) < 0); // 未选中
        this.colData.filter((i) => {
          if (arr.indexOf(i.title) != -1) {
            i.isTrue = false;
            this.$nextTick(() => {
              this.$refs.customerInfoTable.doLayout();
            });
          } else {
            i.isTrue = true;
            this.$nextTick(() => {
              this.$refs.customerInfoTable.doLayout();
            });
          }
        });
        localStorage.setItem('customerInfo', JSON.stringify(this.colData));
      }
    },
    methods: {
      async initLabels() {
        const {success, data} = await getAllLabels();
        if (success && data) {
          let arr = []
          arr.push({label: '全部', value: ''},)
          for (let d of data) {
            arr.push({
              label: d.name,
              value: d.id
            })
            this.labelData = arr
          }
        }
      },
      handReloadCustomerList() {
        this.handleQuery();
      },
      // 客户格式化
      limitTypeFormat(row, column) {
        let value = row[column.property];
        switch (column.label) {
          case '额度状态':
            if (value === '1') {
              return '试用';
            } else if (value === '0') {
              return '正式';
            } else {
              return '';
            }
          case '试用额度到期时间':
            if(value!=null){
              return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');  
            }
            else{
              return '';
            }
          case '试用额度起始时间':
            if(value!=null)
            {
              return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');  
            }
            else{
              return '';
            }
          case '正式额度到期时间' :
            if(value!=null){
              return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');  
            }
            else{
              return '';
            }
          case '正式额度起始时间' :
            if(value!=null){
              return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');  
            }
            else{
              return '';
            }
          case '测试额度到期时间'   :
            if(value!=null){
              return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');  
            }
            else{
              return '';
            }
          case '测试额度起始时间':
            if(value !=null){
              return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');  
            }
            else{
              return '';
            }
          
          default:
            return value;  
        }
      },
      // 弹窗控制
      handleShowDialog(type, val) {
  
        this.editTitle = type == 'insert' ? '创建' : '编辑';
        this.editVisible = true;
        if (val) {
          this.isModify=true
          this.lockObject = JSON.parse(JSON.stringify(val));
        } else {
          this.isModify=false
          this.lockObject = {
            orgId: Number(localStorage.getItem('orgId')),
            code: undefined,
            name: undefined
          };
        }
      },
      // 重置表单
      handleReset() {
        this.filter.orgId = 0;
        this.filter.code = '';
        this.filter.name = '';
        this.filter.taxNo = '';
        // this.filter.contactType = '';
        this.filter.labelId = '';
      },
      // 查询
      async handleQuery(val) {
        this.filter.page = 1;
        if (val) {
          this.filter.page = val;
        }
  
        this.tableLoading = true;
        const { success, data } = await list(this.filter);
        this.tableLoading = false;
        if (success) {
          this.records = data.records;
          this.total = data.total;
        }
      },
      // 切换分页条数
      handleSizeChange(val) {
        this.filter.size = val;
        this.handleQuery();
      },
      // 删除商品
      async handleDelete(row) {
        this.invoiceBatchDeleteVisible = true;
        this.handMatic=row
        this.singleSign = true;
        // this.singleCustomerId = row.id;
      },
      // 关闭商品dialog
      handleCancelEditDialog(status) {
        this.editVisible = false;
        if (status == 'success') {
          this.handleQuery();
        }
        this.$refs.entpriseForm.resetForm();
      },
      /* 表格数据选中 */
      handleSelection(value) {
        this.selections = value;
        this.params.ids = value.map((item) => item.id);
      },
      fmtData(data) {
        return this.$moment(data).format('YYYY-MM-DD HH:mm:ss');  
      },
      //获取列设置
      handleGetlineSet() {
        if (JSON.parse(localStorage.getItem('customerInfo')) != null) {
          this.colData = JSON.parse(localStorage.getItem('customerInfo'));
          if (this.colData.filter((i) => i.isTrue == true).length === this.colSelect.length) {
            this.checkAll = true;
            this.isIndeterminate = false;
          }
          this.colOptions = JSON.parse(localStorage.getItem('customerInfo'))
            .filter((i) => i.isTrue === true)
            .map((item) => item.title); //选中
        } else {
          this.colOptions = this.colData.map((item) => item.title); //默认所有
          this.checkAll = true;
          this.isIndeterminate = false;
        }
      },
      //重置列设置
      handleCheckReset() {
        this.colOptions = this.colSelect;
        this.checkAll = true;
        this.isIndeterminate = false;
      },
      //列设置全选
      handleCheckAllChange(val) {
        this.colOptions = val ? this.colSelect : [];
        this.isIndeterminate = false;
      },
      //列设置改变
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.colSelect.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length;
      },
      //导出excel
      async handleBatchExport() {
        if (this.selections.length == 0) {
          this.toast('请勾选需要导出的客户信息', 'warning');
          return;
        }
        let items = this.selections;
        this.exportLoading = true;
        let ids = items.map((item) => item.id);
        const rsp = await handleExportExcel(ids);
        this.exportLoading = false;
        this.downloadFile(rsp);
      },
      //同步往来单位
      async handleSyncCustom() {
        const { success } = await syncCustom('');
        if (success) {
          this.toast('同步数据成功', 'success', () => this.handleQuery());
        } else {
          this.toast('同步数据失败！', 'error');
        }
      },
      //推送往来单位
      async handlePushCustom() {
        // 判断this.params.ids是否为空
        if (this.params.ids.length == 0) {
          this.toast('请勾选需要推送的客户信息', 'warning');
          return;
        }
        const { success } = await pushCustomer(this.params);
        if (success) {
          this.toast('推送客户信息成功', 'success', () => this.handleQuery());
        }
      },
      /* 发票删除弹窗 */
      handleBatchDelete() {
        if (this.selections.length == 0) {
          this.toast('请勾选客户信息！', 'warning');
          return;
        }
        this.invoiceBatchDeleteVisible = true;
      },
      
      /* 批量删除选中票据 */
      async confirmRenew() {
        if (this.singleSign) {
          // 单条删除
          // let ids = this.singleCustomerId;
          this.handMatic.renewTyope=this.renewTyope
          const { success, message } = await handElecEnt(this.handMatic);
          if (success) {
            this.toast('续费成功', 'success', () => this.handleQuery());
            this.invoiceBatchDeleteVisible = false;
            this.renewTyope=undefined
            this.cancelRenew();
          } else {
            this.toast(message, 'error');
            this.cancelRenew();
          }
        } else {
          // 批量删除
          let ids = this.selections.map((i) => i.id);
          const { success, message } = await deleteCustomerByIds(ids);
          if (success) {
            this.toast('删除成功！', 'success', () => {
              this.invoiceBatchDeleteVisible = false;
              this.handleQuery();
              this.cancelRenew();
            });
          } else {
            this.toast(message, 'error');
            this.cancelRenew();
          }
        }
        // 重置单条删除标记
        this.singleSign = false;
      },
      /* 取消选中 */
      cancelRenew() {
        this.selections = [];
        this.invoiceBatchDeleteVisible = false;
        this.$refs.customerInfoTable.clearSelection();
      },
      settingOptions() {}
    }
  };
  </script>
  
  <style scoped lang="scss">
  .p-header {
    .header-title {
      display: flex;
      padding: 16px 24px;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
  
      .title-right {
        display: inline-flex;
  
        span {
          color: #3d94ff;
          margin-right: 20px;
          font-weight: 500;
          font-size: 16px;
  
          img {
            width: 18px;
            height: 18px;
            vertical-align: sub;
            margin-right: 4px;
          }
        }
  
        span:hover {
          cursor: pointer;
        }
      }
    }
  }
  
  ::v-deep .dialog-footer {
    padding-top: 25px;
    text-align: center;
  }
  
  ::v-deep .p-content {
    .main-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
  
      .top-left {
        display: flex;
        justify-content: flex-start;
  
        span {
          line-height: 32px;
          font-size: 16px;
          color: #666666;
        }
  
        .rowSet {
          display: flex;
          align-items: center;
          cursor: pointer;
  
          .imgSet {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-left: 5px;
          }
  
          span {
            color: #1890ff;
          }
        }
      }
    }
  }
  
  .part {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    margin: 15px 0;
  }
  
  .dialog-wrap {
    display: flex;
    height: 160px;
    flex-direction: column;
  
    .dialog-select {
      margin-bottom: 24px;
  
      label {
        width: 80px;
        text-align: left;
        display: inline-block;
      }
  
      label:before {
        content: '*';
        color: #f5222d;
        margin-right: 2px;
      }
  
      i {
        left: 140px;
        display: block;
        color: #f5222d;
        margin-top: 4px;
        position: absolute;
      }
    }
  
    .dialog-button {
      margin-top: 20px;
      margin-left: 110px;
    }
  
    .backImg {
      bottom: 0;
      width: 100%;
      opacity: 0.5;
      height: 44px;
      position: absolute;
    }
  }
  </style>
  