/**
 * 数电企业信息
 *
 */

/**
 * ENTERPRISE_INFO_LIST
 * 客户信息列表字段
 */
export const ENTERPRISE_INFO_LIST = [

    {
      title: '纳税人名称',
      isTrue: true
    },
    // {
    //   title: '往来类别',
    //   isTrue: true
    // },
    // {
    //   title: '客户类型',
    //   isTrue: true
    // },
  
    {
      title: '纳税人识别号',
      isTrue: true
    },
    {
      title: '额度状态',
      isTrue: true
    },
    {
      title: '省份',
      isTrue: true
    },
    {
      title: '创建时间',
      isTrue: true
    },
    {
      title: '过期时间',
      isTrue: true
    },
    {
      title: '正式额度到期时间',
      isTrue: true
    },
    {
      title: '正式额度起始时间',
      isTrue: true
    },
    {
        title: '测试额度到期时间',
        isTrue: true
    },
    {
        title: '测试额度起始时间',
        isTrue: true
    },
    {
        title: '试用额度到期时间',
        isTrue: true
    },
    {
        title: '试用额度起始时间',
        isTrue: true
    },
    {
      title: '操作',
      isTrue: true
    },
  ];
/**
 * 省份常量
 */
export const PROVINCE_INFO = [
  {
    value: '安徽',
    label: '安徽'
  },
  {
    value: '北京',
    label: '北京'
  },
  {
    value: '大连',
    label: '大连'
  },
  {
    value: '福建',
    label: '福建'
  },
  {
    value: '甘肃',
    label: '甘肃'
  },
  {
    value: '广东',
    label: '广东'
  },
  {
    value: '广西',
    label: '广西'
  },
  {
    value: '贵州',
    label: '贵州'
  },
  {
    value: '海南',
    label: '海南'
  },
  {
    value: '河北',
    label: '河北'
  },
  {
    value: '河南',
    label: '河南'
  },
  {
    value: '黑龙江',
    label: '黑龙江'
  },
  {
    value: '湖北',
    label: '湖北'
  },
  {
    value: '湖南',
    label: '湖南'
  },
  {
    value: '吉林',
    label: '吉林'
  },
  {
    value: '江苏',
    label: '江苏'
  },
  {
    value: '江西',
    label: '江西'
  },
  {
    value: '辽宁',
    label: '辽宁'
  },
  {
    value: '内蒙古',
    label: '内蒙古'
  },
  {
    value: '宁波',
    label: '宁波'
  },
  {
    value: '宁夏',
    label: '宁夏'
  },
  {
    value: '青岛',
    label: '青岛',
  },
  {
    value:  '青海',
    label:  '青海',
  },

  {
    value:  '山东',
    label:  '山东',
  }, {
    value: '山西',
    label: '山西'
  },
  {
    value: '陕西',
    label: '陕西'
  },
  {
    value: '上海',
    label: '上海'
  },
  {
    value: '深圳',
    label: '深圳',
  },
  {
    value:  '四川',
    label:  '四川',
  },
  {
    value:  '天津',
    label:  '天津',
  },
  {
    value:  '西藏',
    label:  '西藏',
  },
  {
    value:  '厦门',
    label:  '厦门',
  },
  {
    value:  '新疆',
    label:  '新疆',
  },
  {
    value:  '云南',
    label:  '云南',
  },
  {
    value:  '浙江',
    label:  '浙江',
  },
  {
    value:  '重庆',
    label:  '重庆',
  }

];

/**
 * 身份
 */
export const ELEC_TYPE=[
  {
    value: '01',
    label: '法定代表人'
  },
  {
    value: '02',
    label: '财务负责人'
  },
  {
    value: '03',
    label: '办税人'
  },
  {
    value: '05',
    label: '管理员'
  },
  {
    value: '08',
    label: '社保经办人'
  },
  {
    value: '09',
    label: '开票员'
  },
  {
    value: '10',
    label: '销售人员'
  }
]
  