import { get, post, download, del, put } from './index';

/**
 * 获取列表列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const list = (data) => {
  return get('/elecEnterprise/list', data).then((res) => res);
};


/**
 * 新增数电企业信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const insertElecEnt = (data) => {
  return post('/elecEnterprise/insert', data).then((res) => res);
};

/**
 * 修改数电企业信息
 */
export const editElecEnt = (data) => {
    return post('/elecEnterprise/edit', data).then((res) => res);
  };

  /**
 * 手动续费数电企业信息
 */
export const handElecEnt = (data) => {
  return post('/elecEnterprise/handMaticRenewal', data).then((res) => res);
};