<template>
    <div class="content-wrap">
      <div class="create-header">
        <div class="header-top">
          <span>{{ title }}</span>
          <div></div>
        </div>
        <div class="header-bottom">
          <el-form ref="entpriseForm" :model="formInfo" :rules="ruless" label-width="140px">
            <el-row>
              <el-col :span="8">
               <el-form-item
                  class="select-width"
                  label="纳税人识别号"
                  prop="kpdwdm"

                >
                  <el-input v-model="formInfo.kpdwdm" :disabled="isModify"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="纳税人名称"
                  prop="kpdwmc"
                 
                >
                  <el-input v-model="formInfo.kpdwmc" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="省份"
                  prop="prov"
                  clearable
                >
                <el-select v-model="formInfo.prov" @change="changeNoYes">
                  <el-option v-for="item in provinceOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>
  
            <el-row>
              <el-col :span="8">
                <el-form-item label="登录类型" prop="fwqlx" >
                    <el-select v-model="formInfo.fwqlx" @change="changeNoYes" clearable>
                        <el-option v-for="item in fwqlxOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="扣除额度方式" v-if="!isModify">
                  <el-select v-model="formInfo.limitType" @change="changeNoYes" clearable>
                        <el-option v-for="item in limitTypeOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="电子税务局身份"
                  prop="dzswjsf"
                >
                <el-select v-model="formInfo.dzswjsf" @change="changeNoYes" clearable>
                        <el-option v-for="item in elecTypeOptions" :key="item.key" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
              </el-col>
            </el-row>
  
            <el-row>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="电子税务局账号"
                  prop="dzswjzh"
                >
                  <el-input v-model="formInfo.dzswjzh" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="电子税务局密码"
                  prop="dzswjmm"
                  label-width="20"
                >
                  <el-input type="password" v-model="formInfo.dzswjmm" show-password></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="确认密码"
                  prop="relativeName"
                >
                  <el-input type="password" v-model="formInfo.qrmm" show-password></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="企业到期时间" prop="gqsj">
                    <el-date-picker v-model="formInfo.gqsj" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"> </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
  
            <el-row>
              
              <!-- <el-col :span="8">
                <el-form-item label="开票地址及电话">
                  <el-input v-model="formInfo.addressAndPhone"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  class="select-width"
                  label="期初已开票金额"
                  prop="invoiceAmount"
                >
                  <el-input-number
                    v-model="formInfo.invoiceAmount"
                    :min="0"
                    :controls="false"
                    :precision="2"
                  />
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
         </div>
       </div>
  
        <div class="edit-select">
          <div class="edit-select-foot">
            <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
            <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveElecEnt" type="primary">保存</el-button>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import {tableStyle} from '@/util/mixins';
  import RegexLib from '@/assets/js/regex-lib'
  import {
    selectproject,
    getProjectPrepayByProjectId,
    getProjectConstructionByProjectId
  } from '@/service/elecEnt'
  import {insertElecEnt} from '@/service/elecEnt';
  import {list} from '@/service/contract.js';
  import {getInfosByOrgId} from '@/service/paas/paas-service'
import { PROVINCE_INFO,ELEC_TYPE } from '../../../content/enterprise';
  
  export default {
    name: 'EnterpriseEdit',
    // components: { OrganizationSelect },
    mixins: [tableStyle],
    props: {
      lockObject: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isModify: {
        type: Boolean,
        default: false
    }
    },
    data () {
      return {
        tableData: [],
        //项目涉税类型
        fwqlxOptions:[
            {
                label:"数电（口令）",
                value:4
            }
        ],
        limitTypeOptions:[
          {
            label:"正式",
            value:0
          },{
            label:"试用",
            value:1,
          }
        ],
        provinceOptions:PROVINCE_INFO,
        elecTypeOptions:ELEC_TYPE,
        //计税方式
        options: [
          {
            value: 0,
            label: '否'
          }, {
            value: 1,
            label: '是'
          }
        ],
        // formInfo:{
        //     limitType:0,
        // },
        title: '数电企业',
        isEdit: false,
        imageUrl: '',
        formInfo: this.lockObject,
        contractRecords: [],
        projectConstructionFormInfo: {
          id: null,
          projectId: null,
          permitCode: '',
          certificateAgency: '',
          isRegistration: 0,
          certificateTime: null,
          county: '',
          province: '',
          city: '',
          projectAddress: '',
          projectDetailedAddress: '',
          startDate: null,
          progress: null,
          expectedEndDate: null,
          endDate: null,
          buildingScale: null,
          projectStatus: null,
        },
        projectPrepayFormInfo: {
          id: null,
          projectId: null,
          isVatTax: 0,
          vatTaxationBasis: 0,
          vatTax: null,
          isUrbanTax: 0,
          urbanTaxationBasis: 0,
          urbanTax: null,
          isEduAttachTax: 0,
          eduAttachTaxationBasis: 0,
          eduAttachTax: null,
          isLocalEduTax: 0,
          localEduTaxationBasis: 0,
          localEduTax: null,
          isCorpIncomeTax: 0,
          corpIncomeTaxationBasis: 0,
          corpIncomeTax: null,
          isStampTax: 0,
          stampTaxationBasis: 0,
          stampTax: null,
          isWaterConservancyFundTax: 0,
          waterConservancyFundTaxationBasis: 0,
          waterConservancyFundTax: null,
          isPersonalTax: 0,
          personalTaxationBasis: 0,
          personalTax: null,
          isDisabilitiesFundTax: 0,
          disabilitiesFundTaxationBasis: 0,
          disabilitiesFundTax: null,
          isUnionDuesTax: 0,
          unionDuesTaxationBasis: 0,
          unionDuesTax: null,
          isResourceTax: 0,
          resourceTaxationBasis: 0,
          resourceTax: null,
          isRefuseDisposalTax: 0,
          refuseDisposalTaxationBasis: 0,
          refuseDisposalTax: null,
          isEnvironmentalProtectionTax: 0,
          environmentalProtectionTaxationBasis: 0,
          environmentalProtectionTax: null,
        },
        businessLicense: '',
        rules: {
        kpdwdm: [
          { required: true, message: '纳税人识别号不允许为空' },
          { max:20, trigger: 'blur' }
        ],
        kpdwmc: [
          { required: true, message: '纳税人名称不允许为空' ,trigger:'blur'},
          
        ],
        prov: [
          { required: true, message: '省份不允许为空',trigger: 'blur' },
        //   { min: 2, max: 8, message: '长度为 2~8 个字符', trigger: 'blur' }
        ],
        fwqlx: [
          { required: true, message: '登录类型不能为空',trigger:'blur' },
        ],
        },
        saveBtnLoading: false
      }
    },
    created () {
      this.isEdit = this.$route.query.isEdit
      if (this.isEdit) {
        this.title = '编辑组织'
        let id = this.$route.query.id
        this.handleSelectproject(id)
        this.renderTable();
      // } else {
      //   this.tableData = this.chanelData
      }
  
      // this.searchContractByProjectId();
      // this.getProjectPrepayByProjectId();
      // this.getProjectConstructionByProjectId();
  
    },
    computed: {
      ruless: function () {
        return this.rules
      }
    },
    methods: {
      async renderTable() {
        const {success, data} = await getInfosByOrgId(this.$route.query.id);
        if (success && data.length > 0) {
          this.tableData = data
        // } else {
        //   this.tableData = this.chanelData
        }
      },
      changeNoYes() {
        this.$refs.entpriseForm.clearValidate()
      },
      // 点击取消的回调
      handleCancel(status) {
        this.$emit('handleCancelEditDialog', status);
      },
      // 点击提交 -> 参数校验
      handleSaveElecEnt() {
        this.$refs.entpriseForm.validate((valid) => {
          if (valid) this.submitElec();
        });
      },
      // 保存
      async submitElec() {
        // loading保存按钮阻止反复提交
        this.saveBtnLoading = true;
        // let projectData = {
        //     ...this.formInfo,
        //     projectPrepay: this.projectPrepayFormInfo,
        //     projectConstruction: this.projectConstructionFormInfo,
        // }
        console.log("请求参数"+JSON.stringify(this.formInfo))
        //todo 记得改
        // if(this.formInfo.limitType){
        //     this.formInfo.limitType=1
        // }
        // else{
        //     this.formInfo.limitType=1
        // }
        this.formInfo.fwqlx=4
        if(this.formInfo.id > 0 ){
          if(this.formInfo.dzswjzh==null||this.formInfo.dzswjzh==""){
            this.toast("修改数电企业时电子税务局账号不能为空");
            return;
          }
          if(this.formInfo.dzswjmm==null || this.formInfo.dzswjmm==""){
            this.toast("修改数电企业时电子税务局密码不能为空")
            return;

          }
        }
        // if(this.formInfo.dzswjmm!=null){
        //   if(this.formInfo.dzswjmm)
        // }
        const { success } = await insertElecEnt(this.formInfo);
  
        const message = this.formInfo.id > 0 ? '修改' : '新增';
        this.saveBtnLoading = false;
        if (success) {
          this.toast(`${message}数电企业成功`, 'success', () => this.handleCancel('success'));
        //   if (this.scene == 'Billing' && this.isRetail) {
        //     this.$emit('edit-finish', this.info);
        //   }
        } else {
          this.toast(`${message}数电企业失败`, 'error');
        }
      },
      handIsDisabled() {
        return this.isEdit;
      },

      // 合同状态
      statusFormat(row, column) {
        let value = row[column.property];
        switch (column.label) {
          case '合同状态':
            if (value === 1) {
              return '正常';
            } else if (value === 2) {
              return '终止';
            } else {
              return '';
            }
        }
      },
      // 获取项目涉及预交信息
      // getProjectPrepayByProjectId() {
      //   if (this.formInfo.id) {
      //     getProjectPrepayByProjectId({
      //       projectId: this.formInfo.id
      //     }).then(res => {
      //       if (res.success && res.data) {
      //         console.log(res.data)
      //         this.projectPrepayFormInfo = res.data
      //       }
      //     })
      //   }
      // },
      // 获取合同
      handleContract() {
        if (this.formInfo.id) {
  
        }
      },
      // 获取合同列表
      searchContractByProjectId() {
        if (this.formInfo.id) {
          list({
            projectId: this.formInfo.id
          }).then(res => {
            if (res.success && res.data) {
              console.log(res.data)
              this.contractRecords = res.data.records
            }
          })
        }
      },
      // 获取项目施工信息
      // getProjectConstructionByProjectId() {
      //   if (this.formInfo.id) {
      //     getProjectConstructionByProjectId({
      //       projectId: this.formInfo.id
      //     }).then(res => {
      //       if (res.success && res.data) {
      //         console.log(res.data)
      //         this.projectConstructionFormInfo = res.data
      //       }
      //     })
      //   }
      // },
      // 获取组织详情
      handleSelectproject(id) {
        selectproject(id).then(res => {
          if (res.success) {
            console.log(res.data)
            this.formInfo = res.data
            this.imageUrl = res.data.fileUrl
          }
        })
      },
      // 禁止输入特殊字符
      handleNoInputSpecialCharacter (e) {
        let flag = RegexLib.TaxNo
        e.target.value = e.target.value.replace(flag, '')
      },
    }
  }
  </script>
  <style lang="scss" scoped>
    .create-header {
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
  
      .header-top {
        padding: 16px 24px;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #e9e9e9;
        color: #333;
        display: flex;
  
        div {
          width: 406px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #F5222D;
          line-height: 20px;
          margin-left: 16px;
        }
  
        span:before {
          content: "|";
          display: inline-block;
          font-size: 16px;
          margin-right: 10px;
          background-color: #333;
        }
  
      }
  
      .header-bottom {
        margin-bottom: 24px;
  
        .el-row {
          margin: 24px 0px;
  
          &:last-child {
            padding-bottom: 20px;
          }
        }
  
        label {
          width: 70px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 20px;
        }
  
        span {
          width: 56px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
        }
  
      }
    }
  
    .create-main {
      width: 100%;
      height: 380px;
      background-color: #fff;
      overflow-x: auto;
      margin-bottom: 24px;
  
      .header-top {
        padding: 16px 24px;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #e9e9e9;
        color: #333;
        display: flex;
  
        div {
          width: 406px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #F5222D;
          line-height: 20px;
          margin-left: 16px;
        }
  
        span:before {
          content: "|";
          display: inline-block;
          font-size: 16px;
          margin-right: 10px;
          background-color: #333;
        }
        
        .top-right {
          width: 906px;
          height: 20px;
          text-align: right;
        }
  
      }
  
      .license-title {
        height: 40px;
        margin: 24px;
        background: rgba(235, 244, 255, 1);
        display: flex;
        align-items: left;
        padding-left: 24px;
  
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
  
        span {
          width: 1082px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
  
      .license-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
  
        .license-img {
          width: 180px;
          height: 240px;
          background: rgba(229, 229, 229, 1);
          margin-bottom: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          .license-show {
            width: 180px;
            height: 240px;
          }
  
          img {
            width: 58px;
            height: 58px;
          }
        }
  
        .el-button {
          width: 120px;
        }
      }
      
      .header-bottom {
        margin-bottom: 24px;
  
        .el-row {
          margin: 24px 0px;
  
          &:last-child {
            padding-bottom: 20px;
          }
        }
  
        label {
          width: 70px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 20px;
        }
  
        span {
          width: 56px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
        }
  
      }
  
      .create-main-row {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        text-align: top;
  
        .el-form-item {
          width: 160px;
        }
  
        .el-select {
          width: 100%;
        }
        
          .el-input el-input--suffix {
            width: 100px;
          }
        .el-checkbox__label {
          width: 150px;
        }
      }
  
    }
  
    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
  
      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }
    .edit-select {
      height: 60px;
    }
    
    .el-input-number {
      width: 200px;
      
      .el-input__inner {
        text-align: right;
      }
  
    }
  </style>
  <style>
    .el-form-item__error {
      top: 30px;
    }
  </style>
  